@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-200 dark:bg-gray-900 bg-opacity-50 text-gray-900 dark:text-gray-300 antialiased;
  }
  h1 {
    @apply font-extrabold text-2xl sm:text-3xl;
  }
  h2 {
    @apply font-extrabold text-xl sm:text-2xl;
  }
  h3 {
    @apply font-bold text-lg sm:text-xl;
  }
  h4 {
    @apply font-semibold text-base sm:text-lg;
  }
  h5 {
    @apply font-semibold text-sm sm:text-base;
  }
  h6 {
    @apply font-semibold text-xs;
  }
  a {
    @apply text-sky-600 hover:text-sky-700 active:text-sky-800 font-semibold;
  }

  hr {
    @apply w-full border-0 mx-0 my-8 bg-gray-100 dark:bg-gray-700;
    height: 1px;
  }

  pre {
    @apply w-full whitespace-pre-wrap;
  }

  label {
    @apply whitespace-nowrap flex-shrink-0 font-semibold opacity-70;
  }

  table {
    @apply w-full;
  }
  table thead th {
    @apply p-2 text-left;
  }
  table tbody td,
  table tfoot td {
    @apply p-2;
  }

  table.with-border th,
  table.with-border td {
    @apply border border-opacity-70 dark:border-gray-700 p-2;
  }

  table.zebra tbody tr:nth-child(odd) {
    @apply bg-gray-50 dark:bg-gray-800;
  }
  table.zebra tbody tr:nth-child(even) {
    @apply bg-gray-200 dark:bg-gray-700;
  }

  table.with-line > thead > tr > th,
  table.with-line > tbody > tr > td {
    @apply border-b border-gray-100 dark:border-gray-700;
  }
  table.rounded-table {
    @apply bg-white rounded-xl overflow-auto;
  }
  table.rounded-table td,
  table.rounded-table th {
    @apply px-4;
  }

  *:focus {
    outline: none;
  }

  .bounce {
    transition: all 0.3s cubic-bezier(0.1, 1.11, 0.41, 2.05);
  }
  .bounce-5 {
    transition: all 0.5s cubic-bezier(0.1, 1.11, 0.41, 2.05);
  }
  .bounce-10 {
    transition: all 0.5s cubic-bezier(0.5, 1.11, 0.41, 2.05);
  }
}

@layer components {
  /* Spinner */
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid #fff;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Colors */
  .top-48 {
    top: 48px;
  }

  .w-240 {
    width: 250px;
  }
  .w-full-240 {
    width: calc(100% - 250px);
  }
  .min-w-400 {
    min-width: 400px;
  }
  .ml-240 {
    margin-left: 250px;
  }

  .left-240 {
    left: 250px;
  }
  .-left-240 {
    left: -250px;
  }
  .text-xxs {
    font-size: 0.5rem;
  }

  .animate-ease-1 {
    transition: all 0.1s ease;
  }
  .animate-ease-2 {
    transition: all 0.2s ease;
  }
  .animate-ease-3 {
    transition: all 0.3s ease;
  }
  .animate-ease-5 {
    transition: all 0.5s ease;
  }
  .animate-cuber-5 {
    transition: all 0.4s cubic-bezier(0.3, 0.8, 0.4, 1);
  }

  /* Page */
  .page-header-container {
    @apply flex py-3 px-4 border-b bg-gray-200;
  }
  .page-header {
    @apply flex justify-between flex-1;
  }

  /* Navigation */
  .main-nav {
    width: 240px;
    @apply sticky flex flex-col p-4 h-screen z-30;
  }

  .main-nav a {
    @apply text-gray-100;
  }

  .main-nav,
  .main-container {
    transition: margin 0.3s;
  }

  .show-menu .main-nav {
    margin-left: 0;
    width: 100%;
  }

  .nav-item > * {
    @apply truncate cursor-pointer items-center flex text-sm lg:text-xs no-underline text-left font-normal;
  }
  .nav-item svg {
    @apply ml-4;
  }
  .nav-item div {
    @apply px-3 py-2 pl-0 ml-4 flex-1 border-b border-gray-700;
  }
  .nav-item div:hover,
  .nav-item a:hover div,
  .nav-item a.active div {
    @apply border-sky-700;
  }
  .nav-item:last-child div {
    @apply border-b-0;
  }
  .nav-item > *:hover,
  .nav-item > *.active {
    @apply bg-sky-600 text-white border-sky-600;
  }

  /* DayPickerInput */
  .DayPickerInput > input {
    @apply text-center text-sm py-2;
  }
  .DayPicker
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    @apply bg-sky-700;
  }
  .DayPicker .DayPicker-Day--today {
    @apply text-red-700;
  }

  /* Product Page */
  .product-cell {
    @apply border-r border-b px-4 whitespace-nowrap;
  }

  /* Shipment */
  .shipment-status {
    @apply font-bold text-xs border-2 text-gray-700 rounded-full border-sky-600 inline-block whitespace-nowrap px-3 py-1;
  }

  /* AMap */
  .amap-marker-label {
    @apply bg-white border-none rounded-xl px-4 py-2 opacity-75 text-center font-bold;
  }

  /* Card */
  .card {
    @apply rounded-2xl px-8 py-6 bg-white dark:bg-gray-700 dark:bg-opacity-20;
  }
  .card-in-modal {
    @apply rounded-2xl px-8 py-6 shadow-sm bg-white dark:bg-gray-900;
  }

  /* Box */
  .box {
    @apply rounded-xl p-6 border border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300;
  }
  .box-2 {
    @apply rounded-xl p-6 border bg-gray-100 dark:border-gray-600 dark:bg-gray-800 text-gray-700 dark:text-gray-300;
  }

  table {
    @apply overflow-auto;
  }

  table.production-calendar td,
  table.production-calendar th {
    @apply border dark:border-gray-700;
  }
  table.production-calendar td {
    @apply py-1;
  }

  .gallery-slick-dots {
    @apply absolute bottom-6 space-x-2 w-full text-center;
  }
  .gallery-slick-dots > li {
    @apply inline-block align-middle;
  }
  .gallery-slick-dots > li button {
    font-size: 0;
    width: 8px;
    height: 8px;
    @apply mx-1 cursor-pointer rounded-full bg-gray-300 dark:bg-gray-600 transition-all duration-200;
  }
  .gallery-slick-dots > li.slick-active button {
    width: 14px;
    height: 14px;
    @apply bg-gray-600 dark:bg-gray-300;
  }

  [data-tips] {
    @apply z-50 relative overflow-hidden hover:overflow-visible;
  }

  [data-tips]:after {
    @apply rounded-lg absolute left-full top-full translate-x-2 opacity-0 px-3 py-2 bg-blue-100 border border-blue-400 z-50 text-gray-800 whitespace-pre leading-relaxed font-normal;
  }
  [data-tips]:after {
    content: attr(data-tips);
    width: max-content;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  [data-tips]:hover:after {
    @apply opacity-100;
  }
}
